/* animated black and deep blue background */
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  font-size: 100%;
  &.dark {
    background: linear-gradient(45deg, #09778a, #12162d);
  }
  &.light {
    background: white
  }
  background-size: 300% 300%;
  animation: gradient 1s ease infinite;
}


#tsparticles canvas {
  z-index: -1;
}