.card {
    backdrop-filter: blur(10px);
    border-radius: 10px;
    padding: 2em;
    margin-bottom: 1em;
    transition: transform 0.6s ease;

    &.bright-mode {
        background: rgba(0, 0, 0, 0.1);
        color: #282c34;
    }

    &.dark-mode {
        background: rgba(255, 255, 255, 0.3);
        h3, p {
            color: #fff;
        }
    }

    img {
        width: 70%;
        border-radius: 10px;
        transition: transform 0.6s ease; 
    }

    &:hover {
        transform: translateY(-10px);
        box-shadow: 0 2px 5px rgba(0,0,0,0.2);
        transition: transform 0.6s ease;

        img {
            transform: scale(1.1);
            transition: transform 0.6s ease;
        }
    }
}