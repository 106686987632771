.title-block {
    text-align: center;
    margin-bottom: 60px;
    transition: transform 0.6s ease;

    h2 {
        color: white;
        font-size: 4rem;
        font-weight: 700;
        margin: 0.5rem;
        padding: 1rem;
    }

    h3 {
        color: white;
        font-size: 3rem;
        font-weight: 500;
        margin: 0.5rem;
        padding: 1rem;
    }

    p {
        color: white;
        font-size: 2rem;
        font-weight: 400;
        margin: 0.25rem;

    }

    &.bright-mode {
        transition: transform 0.6s ease;

        h2 {
            color: black;
        }
        h3 {
            color: black;
        }
        p {
            color: black;
        }
    }

    @media (max-width: 480px) {
        h2 {
            font-size: 2rem;
        }

        h3 {
            font-size: 1.2rem;
        }

        p {
            font-size: 1rem;
        }
    }
}

