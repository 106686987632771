
/* Navbar styles, animated adding the border */
.navbar {
    position: fixed;
    top: 24px;
    left: 50%;
    transform: translateX(-50%);
    align-items: flex-end;
    height: 36px;
    /*display: flex;*/
    padding: 12px 36px;
    /*padding-bottom: 16px;*/
    gap: 18px;
    will-change: contents;
    box-sizing: content-box;
    border-radius: 12px;
    transform-origin: center bottom;
    transition: all 0.3s ease;
    z-index: 999;

    &.scrolled {
        border-radius: 50px;
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(10px);
        box-shadow: 0 2px 5px rgba(0,0,0,0.2);
        /*transform: translateY(-10px);*/
    }

    ul {
        display: flex;
        gap: 24px;
        margin: 0;
        padding: 0;

        li {
            list-style: none;
            padding: 0.3em 1em;

            &.active {
                background: rgba(255, 255, 255, 0.2);
                backdrop-filter: blur(10px);
                border-radius: 40px;
                padding: 0.3em 1em;
                transition: all 0.3s ease;
            }

            a {
                text-decoration: none;
                color: #fff;
                font-size: 1.2em;
                font-weight: 500;
                transition: all 0.3s ease;
            }
        }
    }

    &.bright-mode {
        &.scrolled {
            background: rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(10px);
            box-shadow: 0 2px 5px rgba(0,0,0,0.3);
        }

        ul li {
            &.active {
                background: rgba(0, 0, 0, 0.1);
            }

            a {
                color: #000;
            }
        }
    }
}

@media (max-width: 480px) {
    .navbar {
        top: 18px;
        padding: 12px 24px;
        gap: 12px;

        ul {
            gap: 12px;
        }
    }
}
