/* ---------- Animation ---------- */
@keyframes spin-right {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes spin-right {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

/* Container for the orbiting circles animation */
#circle-orbit-container {
    position: absolute;
    z-index: -1;
    top: 150px;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 300px;
    width: 300px;
}

/* Inner orbit - This is the circles closest to the central point */
#inner-orbit {
    position: absolute;
    top: 75px;
    left: 75px;
    width: 160px;
    height: 160px;
    border: 2px #d7d4e7 dashed;
    border-radius: 100%;
    -webkit-animation: spin-right 10s linear infinite;
    animation: spin-right 10s linear infinite;
}

/* Repeating styles for the inner orbiting circles */
.inner-orbit-circles {
    position: absolute;
    top: 62px;
    left: -15px;
    height: 30px;
    width: 30px;
    border-radius: 100%;
    background-color: #000000;
    box-shadow: 0px 0px 13px 6px #FFFFFF;
}

/* Outer orbit - This is the circles furthest away from the central point */
#outer-orbit {
    position: absolute;
    top: 0;
    left: 0;
    width: 350px;
    height: 300px;
    border: 2px #9f98ce dashed;
    border-radius: 100%;
    -webkit-animation: spin-right 50s linear infinite;
    animation: spin-right 50s linear infinite;
}

/* Repeating styles for the outer orbiting circles */
.outer-orbit-circles {
    position: absolute;
    top: -6px;
    left: 125px;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    background-color: #000000;
    box-shadow: 0px 0px 10px 6px #FFFFFF;
}

