/* Import simple-grid CSS */

main {
    top: 96px;
}

.grid {
    margin: 0 auto;
    max-width: 1200px;
    padding: 1em;
}

article {
    padding: 3em;
    margin: 1em 0;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: #666666 0px 0px 10px;
    min-height: 600px;
}

/* remove underlines for all links */
a {
    text-decoration: none;
}